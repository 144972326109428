angular.module('matroshkiApp').controller('RegisterInstanceCtrl', function (appSettings, $scope, $uibModalInstance, countries, $http) {

  $scope.errors = {};
  $scope.user = {};
  $scope.user.type = 'member';
  $scope.countries = countries ? countries : [];

  $scope.formSubmitted = false;
  $scope.createFreeAccount = function (form) {
    $scope.errors = {};
    if(!form.$valid){
      return false;
    }
    $scope.formSubmitted = true;
    $http.post(appSettings.BASE_URL + 'api/v1/auth/register', $scope.user)
    .success(function (data) {
      if(data.success) {
        alertify.success(data.messages);
      } else {
        $scope.errors = data.errors;
      }
    })
    .error(function (err) {
      alertify.error('Submit Error');
    }.bind(this));
  };


  $scope.cancel = function () {
    sessionStorage.closePopup = true;
    $uibModalInstance.dismiss('cancel');
  };
});